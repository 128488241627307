<template>
  <div id="app">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
useHead({
  title: 'Home',
  titleTemplate: 'Naam Deo - %s',
  link: [
    { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    { rel: 'preconnect', href: 'https://public-assets.naamdeo.org' },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500&family=Playfair+Display:wght@400;500;600;700;800;900&family=Source+Serif+Pro:wght@200;300;400;600;700;900&display=swap',
    },
  ],
  meta: [
    {
      key: 'keywords',
      name: 'keywords',
      content:
        'Naamdeo, ask naam deo, highlights, videos, quotes, podcasts, Vihangam Yoga, Abhyas School Of Yoga, name tree, blog, events',
    },
    { charset: 'utf-8' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0',
    },
  ],
});
useSeoMeta({
  description:
    'These hands are full with things holding us back for rather too long. They seek to be freed to enable hand-holding towards our highest purpose. - Naam Deo',
  formatDetection: 'telephone=no',
  twitterCard: 'summary_large_image',
  twitterSite: '@vybangalor',
  twitterTitle: 'Naam Deo',
  twitterDescription:
    'These hands are full with things holding us back for rather too long. They seek to be freed to enable hand-holding towards our highest purpose. - Naam Deo',
  twitterImage:
    'https://public-assets.naamdeo.org/cdn-cgi/image/fit=scale-down,width=640,quality=75/images/cover.jpg',
  twitterImageAlt: 'Naam Deo',
  ogSiteName: 'Naam Deo',
  ogType: 'website',
  ogUrl: 'https://www.naamdeo.org',
  ogTitle: 'Naam Deo',
  ogDescription:
    'These hands are full with things holding us back for rather too long. They seek to be freed to enable hand-holding towards our highest purpose. - Naam Deo',
  ogImage:
    'https://public-assets.naamdeo.org/cdn-cgi/image/fit=scale-down,width=640,quality=75/images/cover.jpg',
  ogImageSecureUrl: 'https://public-assets.naamdeo.org/images/cover.jpg',
  ogImageAlt: 'Naam Deo',
});
</script>
