import { default as _91slug_93ve1EtQ5OqdMeta } from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/apps/naamdeo-webapp/pages/blog/[slug].vue?macro=true";
import { default as ConnectAO6h7cKPwmMeta } from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/apps/naamdeo-webapp/pages/Connect.vue?macro=true";
import { default as indexnN1p3CX17RMeta } from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/apps/naamdeo-webapp/pages/index.vue?macro=true";
import { default as TeachingsKwN13Neg2BMeta } from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/apps/naamdeo-webapp/pages/Teachings.vue?macro=true";
export default [
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/apps/naamdeo-webapp/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "Connect",
    path: "/Connect",
    component: () => import("/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/apps/naamdeo-webapp/pages/Connect.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/apps/naamdeo-webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "Teachings",
    path: "/Teachings",
    component: () => import("/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/apps/naamdeo-webapp/pages/Teachings.vue").then(m => m.default || m)
  }
]