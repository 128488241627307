import revive_payload_client_F9NQL6Zldv from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.8.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jbhvqhb727spls26o4dy5kfn7i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_q0kK0zvC8F from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.8.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jbhvqhb727spls26o4dy5kfn7i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9O3WnlWamC from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.8.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jbhvqhb727spls26o4dy5kfn7i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_wA4Fo0uerk from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.8.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jbhvqhb727spls26o4dy5kfn7i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_uC5VFTbxCg from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.8.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jbhvqhb727spls26o4dy5kfn7i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bQZYoIXldi from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.8.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jbhvqhb727spls26o4dy5kfn7i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_XqsMrXlWHT from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.8.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jbhvqhb727spls26o4dy5kfn7i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/apps/naamdeo-webapp/.nuxt/components.plugin.mjs";
import prefetch_client_iwo1sI0oYt from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.8.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_jbhvqhb727spls26o4dy5kfn7i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_jPwPdTRqgm from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/node_modules/.pnpm/nuxt-gtag@2.0.7_magicast@0.3.4_rollup@4.19.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_shVUlIjFLk from "/home/runner/work/sukrit-ecosystem-monorepo/sukrit-ecosystem-monorepo/apps/naamdeo-webapp/plugins/sentry.client.ts";
export default [
  revive_payload_client_F9NQL6Zldv,
  unhead_q0kK0zvC8F,
  router_9O3WnlWamC,
  payload_client_wA4Fo0uerk,
  navigation_repaint_client_uC5VFTbxCg,
  check_outdated_build_client_bQZYoIXldi,
  chunk_reload_client_XqsMrXlWHT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_iwo1sI0oYt,
  plugin_client_jPwPdTRqgm,
  sentry_client_shVUlIjFLk
]